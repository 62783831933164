import React from "react";
import {
    //AboutSection,
    //ArticlesSection,
    //ContactSection,
    HeroSection,
    //InterestsSection,
    //ProjectsSection,
    Page,
    Seo,
} from "gatsby-theme-portfolio-minimal";

// REMOVED FROM return OBJECT
// <Page useSplashScreenAnimation>

export default function IndexPage() {
    return (
        <>
            <Seo title="Massage Chai" />
            <Page >
                <HeroSection sectionId="hero" heading="we are massage chai" />
            </Page>
        </>
    );
}